<script lang="ts">
  import Icons from "@tastyworks/icons"
  import { getUiclContext } from "#lib/internal/helpers"
  import { cn } from "#lib/utils"
  import { useActions } from "radix-svelte/internal/helpers"
  import type { HTMLInputAttributes } from "svelte/elements"
  import { inputFieldVariants } from "."

  type $$Events = {
    blur: FocusEvent
    change: Event & { target: HTMLInputElement }
    click: MouseEvent
    focus: FocusEvent
    keydown: KeyboardEvent
    keypress: KeyboardEvent
    keyup: KeyboardEvent
    mouseover: MouseEvent
    mouseenter: MouseEvent
    mouseleave: MouseEvent
    mouseup: MouseEvent
    mousedown: MouseEvent
    paste: ClipboardEvent
    input: InputEvent
    focusout: FocusEvent
    focusin: FocusEvent
    cancel: Event
  }

  let className: string | undefined | null = undefined

  export let value: HTMLInputAttributes["value"] = undefined
  export { className as class }
  export let locked = false
  export let disabled = false
  export let error: boolean | undefined = false
  export let iconClass: string | undefined = undefined
  // internal-use, bind-only
  // NOTE: establish convensions, re-visit ref pattern for other components
  export let ref: HTMLInputElement | undefined = undefined

  const v2 = !!getUiclContext()?.v2
</script>

<div class="relative flex-auto">
  <input
    class={cn(inputFieldVariants({ error, v2 }), className)}
    use:useActions={$$restProps.use}
    bind:value
    bind:this={ref}
    on:blur
    on:change
    on:cancel
    on:click
    on:focus
    on:focusin
    on:focusout
    on:keydown
    on:keypress
    on:keyup
    on:mouseover
    on:mouseenter
    on:mouseleave
    on:mousedown
    on:mouseup
    on:paste
    on:input
    disabled={locked || disabled}
    aria-invalid={error}
    data-value={value ?? ""}
    {...$$restProps}
  />
  <div
    class="pointer-events-none absolute inset-0.5 left-auto mr-3.5 flex items-center"
  >
    <slot />
    {#if locked}
      <Icons.lock
        class={cn(
          "text-general-secondary-label icon-double-extra-large",
          iconClass
        )}
      />
    {/if}
  </div>
</div>

<style lang="postcss">
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:autofill {
    -webkit-box-shadow: 0 0 0 30px
      var(
        --autofill-bg-color,
        var(--color-background-input-field-dropdown-primary)
      )
      inset !important;
    -webkit-text-fill-color: var(
      --autofill-text-color,
      var(--color-text-general-primary-text)
    ) !important;
    -webkit-background-clip: text !important;
  }

  /* hide password reveal in favor of our own */
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
</style>
